import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Upcomingevents.css";

import { Autoplay, EffectCoverflow, Pagination, Navigation } from "swiper";

import Upcomingevents from "../../UpcomingEvents.json";

const Events = ({ downref, handleScrollToDown }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
    // console.log(swiper.activeIndex)
  };

  const handleClick = () => {
    handleScrollToDown();
  };
  return (
    <>
      <div className="backgound-section">
        <div className="container outer-section events__banner">
          {/*<img src="/images/banner.jpg" alt="banner" className="events__banner--image" /> */}
          {/* <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            onSlideChange={handleSlideChange}
            initialSlide={0}
            slidesPerView={1}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Autoplay, Pagination, Navigation]}
            className="swiper_container"
          >
            {Upcomingevents.map((element, index) => (
              <SwiperSlide
                key={index}
                className={
                  activeSlideIndex === index
                    ? "active-slide up-swiper-slide"
                    : "blur-slide"
                }
              >
                <img
                  onClick={handleClick}
                  src={element.img}
                  alt={element.name}
                />
              </SwiperSlide>
            ))}

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper> */}
        </div>
      </div>
    </>
  );
};
export default Events;
