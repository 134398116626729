import React, { useState } from "react";
import Carosel from "../components/Home/Carosel";
import styles from "./rentals.module.css";
import topImage from "../img/EventManagement1.png";
import Hangers from "../components/Rentals/Hangers";
import Stalls from "../components/Rentals/Stalls";
import Pandals from "../components/Rentals/Pandals";
import Stage from "../components/Rentals/Stage";
import Swiper from "../components/Swiper";

// import Rental from '../Rentals.json'

const Rentals = () => {
  const [tag, setTag] = useState("Hangers");
  return (
    <div className={styles.rentals}>
      <div className={styles.TopSection}>
        {/* <div className={styles.imageSection}>
                    <img src={topImage} alt="topImage"></img>
                </div> */}
        <div className={styles.textSection}>
          <h1>Rentals</h1>
          <p>
            Event Solution is the best Rental Service provider in Nepal, an
            event management business that relieves the strain of event
            preparation. We offer complete rental services, including stalls,
            hangers, pandals, stages, and lighting.
          </p>
        </div>
      </div>
      <div className={styles.optionMenu}>
        {/* <input type="checkbox" id={styles.menubar}></input>
                <label for="menu-bar">Menu</label> */}
        <div className={styles.navigation}>
          <ul style={{ marginLeft: "0px" }}>
            <li>
              <TagButton
                name="Hangers"
                handleSetTag={setTag}
                tagActive={tag === "Hangers"}
              />
            </li>
            <li>
              <TagButton
                name="Stalls"
                handleSetTag={setTag}
                tagActive={tag === "Stalls"}
              />
            </li>
            <li>
              <TagButton
                name="Pandals"
                handleSetTag={setTag}
                tagActive={tag === "Pandals"}
              />
            </li>
            <li>
              <TagButton
                name="Stage&LED"
                handleSetTag={setTag}
                tagActive={tag === "Stage&LED"}
              />
            </li>
          </ul>
        </div>
        <hr></hr>
      </div>

      <div className={styles.gallery}>
        {tag === "Hangers" && <Hangers />}
        {tag === "Stalls" && <Stalls />}
        {tag === "Pandals" && <Pandals />}
        {tag === "Stage&LED" && <Stage />}
      </div>
      {/* <Carosel /> */}
      <Swiper />
    </div>
  );
};

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className={`${styles.tag} ${tagActive ? styles.actively : "null"}`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()}
    </button>
  );
};
export default Rentals;
